<template>
    <div class="dial-status-home">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>软路由-拨号状态</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card shadow="never" class="box-card" v-loading="isLoading">
            <h4>拨号状态(只展示拨号异常的节点)</h4>
            <div
                v-if="serverList && serverList.length && serverList !== 'NODATA'"
                v-for="item in serverList"
                class="item-list"
            >
                <div class="first-info">
                    <div class="first-wrap">
                        <div class="host-name margin-bottom-20">主机名：</div>
                        <div class="host-info">{{item.serverHostname}}</div>
                    </div>
                    <div class="first-wrap margin-left-40">
                        <div class="host-name margin-bottom-20">备注:</div>
                        <div class="host-info">{{item.serverNote}}</div>
                    </div>
                    <!-- <div class="first-wrap margin-left-40" v-if="!isSon">
                        <div class="host-name margin-bottom-20">子账号:</div>
                        <div class="host-info">{{item.serverNote}}</div>
                    </div> -->
                </div>
                <div class="second-info">
                    <div>拨号账号总数：{{item.all_count}}</div>
                    <div>拨号正常数量：<span class="right-circle"></span>{{item.normal_count}}</div>
                    <div>拨号异常数量：<span class="error-circle"></span>{{item.abnormal_count}}
                        <span
                                v-if="item.abnormal_count"
                                class="bonus-cursor margin-left-20 bonus-color"
                                @click="showAccounts(item)"
                        >
                                查看详情
                            </span>
                    </div>
                </div>
            </div>
            <div v-if="serverList.length === 0 && total === normal" class="no-data margin-20 text-align-center">
                当前拨号均正常
            </div>
            <div v-if="serverList.length === 0 && total === 0" class="no-data margin-20 text-align-center">
                暂无数据
            </div>
        </el-card>
        <!-- 账号详情 -->
        <el-dialog
                title="异常账号"
                :visible.sync="showDialog"
                width="480px"
                center
        >
            <div class="">
                <div class="displax-flex">
                    <div class="dial-left-wrap">
                        账号
                    </div>
                    <div class="dial-right-wrap">
                        密码
                    </div>
                    <div class="dial-right-wrap">
                        vlan
                    </div>
                </div>
                <div v-for="item in accountList" class="displax-flex">
                    <div class="dial-left-wrap">
                        {{item.account}}
                    </div>
                    <div class="dial-right-wrap">
                        {{item.pass}}
                    </div>
                    <div class="dial-right-wrap">
                        {{item.vlan}}
                    </div>
                </div>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <div
                    class="sure-unbind button"
                    type="primary"
                    @click="showDialog = false"
                >确定</div>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  // @ is an alias to /src
  import { mapState } from "vuex";
  import moment from "moment";
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';

  export default {
    name: 'DialStatus',
    data() {
      return {
        accountList: [],
        showDialog: false,
        total: 0,
        normal: 0,
        error: 0,
        serverList: [],
        isLoading: true,
        serverlistadd:[],//父账号时重新拼接的异常数组
      };
    },
    computed: mapState({
      isSon: state => !!state.accountInfo.parentId,
      userinfo: state => state.accountInfo,
    }),
    async created() {
      this.serverlistadd = [];
      let date_at = moment()
        .utc()
        .startOf('minute')
        .subtract(3, 'minute')
        .valueOf();
      try {
        const res = await fetch(baseUrl + '/api/dialStatus/getDialStatus', {
          date_at,
          type: 'error',
        })
        console.log(res);
        // 如果为父账号
        if(!this.isSon){
          this.total = res.totalall;
          this.normal = res.normalall;
          this.error = res.errorall;
          this.serverList = res.serverListall;
        }else{
          //不是父账号
          const {
            total = 0,
            normal = 0,
            error = 0,
            serverList = []
          } = res;
          this.total = total;
          this.normal = normal;
          this.error = error;
          this.serverList = serverList;
        }
      } catch (e) {
        this.$message({
          message: '查询失败，请稍后重试',
          type: 'error'
        });
      }
      this.isLoading = false;
    },
    
    methods: {
      // showDetail() {
      //   this.getDialStatus({
      //     date_at: moment()
      //       .utc()
      //       .subtract(3, "minute")
      //       .valueOf(),
      //     type: "error"
      //   });
      // },
      showAccounts(item) {
        let list = [];
        for (var i in item.abnormal_list) {
          for(var j in item.abnormal_list[i]){
            list.push({
              account: i,
              pass:j,
              vlan:item.abnormal_list[i][j],
            })
          }
        }
        this.accountList = list;
        this.showDialog = true;
      }
    }
  };
</script>

<style scoped lang="stylus">
    .box-card {
        margin: 10px 0;
    }
    .basic-layout-wrap {
        background: #fff;
        border: 1px solid #e3e3e7;
        position: relative;
    }

    .dial-status-home .item-list {
        border-bottom: 1px solid #ddd;
        margin: 20px;
    }

    .dial-status-home .second-info {
        display: flex;
        margin: 20px;

        // padding: 0 20px;
        div {
            width: 33.33%;
        }
    }

    .dial-status-home .first-info {
        display: flex;
        margin: 20px;

        .first-wrap {
            // margin: 20px;
            // padding: 0 20px;
        }
    }

    .dial-left-wrap, .dial-right-wrap {
        width:50%;
        text-align: center;
        margin-bottom: 20px;
    }

    .host-info {
        color: #65686a;
    }

    .right-circle{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 5px;
        background-color: #282557;
        margin-right: 10px;
    }
    .error-circle{
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 5px;
        background-color: red;
        margin-right: 10px;
    }
</style>
