<template>
  <div class="home">
    <div class="header login-header">
      <div class="header-panel">
        <div class="icon-banner">
          <div class="grid-content bg-purple log-wrap">
        
          </div>
        </div>
      </div>
    </div>
    <AccountLayout class="login-content">
      <!-- 登陆框 -->
      <div class="account-dialog">
        <div class="title">用户登录</div>
        <!-- 邮箱 -->
        <BasicInput
          type="text"
          icon-type="email"
          class="account-input account-email"
          :placeValue="$t('login.loginPlaceHolder')"
          v-model="inputEmail"
        ></BasicInput>
        <div
          v-if="EmailErrorMsg"
          class="account-error"
        >
          <i class="el-alert__icon el-icon-error"></i>
          <span class="EmailErrorMsg">{{ EmailErrorMsg }}</span>
        </div>
        <BasicInput
          type="password"
          icon-type="password"
          class="account-input password-email"
          v-model="inputPassword"
          needKeyEnter=true
          @keyUpEnter="startLogin"
          :placeValue="$t('login.PWPlaceHolder')"
        ></BasicInput>
        <div
          v-if="passwordErrorMsg"
          class="account-error"
        >
          <i class="el-alert__icon el-icon-error"></i>
          <span class="passwordErrorMsg">{{ passwordErrorMsg }}</span>
        </div>
        <!-- 验证码 -->
        <div class="captcha">
          <el-input
            class="account-input account-email"
            v-model="imageCode"
            autocomplete="off"
            placeholder="验证码"
            style="width: 70px;"
          ></el-input>
          <img class="image" :src="imgUrl" />
          <p class="image-remark">看不清？<span style="color: #111135; cursor: pointer;text-decoration: underline;" @click="queryimageCode">换一张</span></p>
        </div>
        <div
          v-if="verifyErrorMsg"
          class="account-error"
        >
          <i class="el-alert__icon el-icon-error"></i>
          <span class="EmailErrorMsg">{{ verifyErrorMsg }}</span>
        </div>
        <!--  登陆 -->
        <div class="login bonus-cursor" v-on:click="startLogin">登录</div>
        <div class="login-sign-wrap">
        </div>
      </div>
    </AccountLayout>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备18045280号-2</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AccountLayout from '@/components/AccountLayout.vue'
import BasicInput from '@/components/User/BasicInput.vue'
import { mapActions } from 'vuex'
import { Message } from 'element-ui'
import VueRecaptcha from 'vue-recaptcha'
import { SITEKEY } from '../../config/contant.js'
import Footer from '@/components/Common/Footer.vue';
import { reqUrl } from '../../utils/env';
import axios from 'axios';

export default {
  name: 'Login',
  components: {
    AccountLayout,
    BasicInput,
    VueRecaptcha,
    Footer
  },
  data () {
    return {
      EmailErrorMsg: '',
      passwordErrorMsg: '',
      verifyErrorMsg:'',
      inputEmail: '',
      inputPassword: '',
      sitekey: SITEKEY['LOW'], //ga verify key
      response: '', //ga verify response
      isLoginDisable: false, //login可点击状态
      imageCode:'',
      imgUrl: '',
      imageCodeId: ''
    }
  },
  created() {
    this.queryimageCode();
  },
  methods: {
    ...mapActions(['login']),
    queryimageCode() {
      this.imageCode = '';
      try {
        axios.get(`${reqUrl}/api/getCaptcha`).then(res=>{
          this.imgUrl = res.data.data.Base64Blob;
          this.imageCodeId = res.data.data.CaptchaId;
        })
      } catch (error) {
          this.imgUrl = '';
          this.imageCodeId = '';
      }
    },
    // 登陆
    startLogin () {
      const {inputEmail, inputPassword, isLoginDisable } = this
      if (isLoginDisable) {
        return true
      }

      if (!inputEmail) {
        this.EmailErrorMsg = '请输入邮箱'
        return true;
      } else {
        // 邮箱正则表达式-----后续建议提出来统一维护
        const emailRule = /^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,6}){1,2})$/
        if (emailRule.test(inputEmail)) {
          this.EmailErrorMsg = ''
        } else {
          this.EmailErrorMsg = '请输入格式正确的邮箱'
          return true
        }
      }

      if(!inputPassword) {
        this.passwordErrorMsg = '请输入密码'
        return true
      } else {
        if (inputPassword.length >= 6 && inputPassword.length < 30) {
          this.passwordErrorMsg = ''
        } else {
          this.passwordErrorMsg = '请输入长度在6到30位之间的密码'
          return true
        }
      }

      if(!this.imageCode) {
        this.verifyErrorMsg = '请输入图片验证码'
        return true;
      } else {
        this.verifyErrorMsg = '';
      }

      this.isLoginDisable = true
      this.login({email: inputEmail, password: inputPassword,captcha_id: this.imageCodeId, captcha: this.imageCode}).then(
        res => {
          if (res.code === 200) {
            Message({
              message: '登录成功',
              type: 'success'
            })
            setTimeout(() => {
              this.$router.push({name: 'resourceIndex'})
            }, 2000)
          } else {
            this.isLoginDisable = false
            Message({
              message: res.message || 'login error',
              type: 'error'
            })
          }
        }
      )
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.is-mobile {
  .account-dialog {
    margin 0
    width 100%
    max-width 100%
  }
  .login-content {
    height auto
  }
}

.login-content
  background: url("../../assets/newBackground.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vw;

.captcha-wrap {
  transform: scale(0.96);
  -webkit-transform: scale(0.96);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  margin: 10px 30px;
}

@media screen and (max-width: 1200px) {
  .captcha-wrap {
    transform: scale(0.78);
    -webkit-transform: scale(0.78);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.account-error {
  box-sizing: border-box;
  padding: 5px 30px 0;
  color: #EE4035;
  font-size: 10px;
}

.el-alert__icon {
  font-size: 10px;
}

.account-dialog {
  width: 52%;
  max-height: 390px;
  max-width: 420px;
  background: #fff;
  border: 1px solid #e3e3e7;
  margin: 18% 0 0 10%;
}

.account-dialog .title {
  font-family: PingFangSC-Semibold;
  color: #65686A;
  font-size: 12px;
  line-height: 12px;
  padding: 30px 0 30px 40px;
  text-align: left;
}

.account-dialog .title {
  font-size: 14px;
  line-height: 14px;
  padding: 35px 0 35px 30px;
  text-align: left;
}

.input-wrap {
  margin: 10px 40px 20px 40px;
}

.login {
  box-sizing: border-box;
  margin: 15px 30px 5px 30px;
  background-color: #282557;
  line-height: 30px;
  height: 30px;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #FFFFFF;
  text-align: center;
  line-height: 30px;
}

@media screen and (min-width: 1200px) {
  .account-dialog {
    width: 350px;
    // height: 320px;
  }
}

@media screen and (max-width: 1200px) {
  .account-dialog {
    width: 300px;
    // height: 280px;
    max-height: 390px;
    max-width: 420px;
    background: #fff;
    border: 1px solid #e3e3e7;
    margin: 120px 0 0 98px;
  }
}

.account-input {
  margin: 0 30px;
}

.BasicInput.account-input.password-email {
  margin-top: 12px;
}

.login-sign-wrap {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #282557;
  text-align: justify;
  line-height: 36px;
  margin: 0 30px 35px;
  display: flex;
  justify-content: space-between;
}

.login-header {
  background: rgb(17,17,53);
}

.header-logo {
  width: 130px;
  // height: 23.5px;
  height:50px;
  margin: 19px 25px;
}
.logo{
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  width: 300px;
  height: 57px;
  padding-top:15px;
  margin-left:50px;
  // margin: 19px 25px;
}

.log-wrap{
  // padding: 19px 25px;
}

.captcha {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 30px;
  .image {
    width: 90px;
    height: 30px;
    background: #F7F7F7;
    margin-top: 12px;
  }
  .image-remark {
    font-size: 12px;
    margin-top: 12px;
  }
}
</style>

<style lang="stylus">
  .captcha {
    .el-input__inner {
      width: 100px;
      height: 30px;
      border-radius: 0px;
      margin-top: 12px;
    }
  }
</style>