<template>
  <div
    id="app"
    style="height: 100%;"
  >
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style lang="stylus">
html {
  overflow-x: hidden;
}

body {
  &.overflow-hidden {
    overflow: hidden;
  }
}

.guid-link:hover {
  color: #666;
  font-size: 12px;
}

html, body {
  height: 100%;
  font-family: 'Avenir', Arial, sans-serif, Helvetica, Tahoma, Arial, STXihei, '华文细黑', Heiti, '黑体', 'Microsoft YaHei', '微软雅黑', SimSun, '宋体', sans-serif;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: #0A0E22;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align center
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #282557;
    }
  }
}

a:active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

.bonus-cursor {
  cursor: pointer;
}

.input {
  width: 200px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 30px;
  outline: none;
  padding: 0 10px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 16px;
}

.button {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #282557), to(#282557));
  background-image: linear-gradient(-180deg, #282557 2%, #282557 100%);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  width: 90px;
  height: 35px;
  cursor: pointer;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 18px;
  border-radius: 0;
  border: 0;
  display: inline-block;
  width: 90px;
  height: 35px;
  line-height: 35px;
  margin: 10px;
}

.red-button {
  background: #f56c6c;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 18px;
  border-radius: 0;
  border: 0;
  width: 90px;
  height: 35px;
  line-height: 35px;
  margin: 10px;
  cursor: pointer;
}

.loading {
  color: #909399;
  line-height: 30px;
}

.link {
  font-size: 10px;
  color: #282557;
  line-height: 42px;
}

.button-disabled {
  background: #d0d0d0 !important;
}

.none {
  display: none !important;
}

.el-menu-item-group__title {
  padding: 3px !important;
}

.el-tooltip__popper {
  max-width: 300px;
  line-height: 180%;
}

.margin-top-20 {
  margin-top: 20px;
}

.bonus-tips {
  color: #F56C6C;
}

.bonus-over-ellip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.background-ddd {
  background: #ddd !important;
}

.el-dialog__title {
  font-size: 26px !important;
}

.common-left-wrap {
  width: 100%;
  color: #96999b;
  text-align: right;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  margin: 10px 0;
}

.common-right-wrap {
  padding-left: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  margin: 10px 0;
}

.displax-flex {
  display: flex;
}

.margin-left-40 {
  margin-left: 40px;
}

.el-button--primary {
  background-color: #282557 !important;
  border-color: #282557 !important;
}

.border {
  border-top: 1px solid #dcdfe6;
  width: 80%;
  margin: 0 auto;
}

.el-dialog__body, .el-dialog__footer {
  display: inline-block;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px !important;
}

.el-dialog__header, #app .el-dialog__footer {
  text-align: center;
}

/* * 防止出现弹框时 页面上弹到顶部 */
body.el-popup-parent--hidden {
  overflow: visible;
}

// Common CSS
.justify-start {
  display: flex;
  justify-content: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-10 {
  margin: 10px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-20 {
  margin: 20px;
}

.text-align-center {
  text-align: center;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-right-30 {
  margin-right: 30px;
}

.bonus-color {
  color: #282557;
}

.margin-right-10 {
  margin-right: 10px;
}

input.bonus-basic-input {
  height: 30px;
  line-height: 30px;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #000;
  display: inline-block;
  font-size: inherit;
  outline: none;
  padding: 0 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-size: 10px;
}

input.bonus-big-input {
  height: 40px;
  line-height: 40px;
  -webkit-appearance: none;
  background-color: #fff;
  padding: 0 10px;
  width: 100%;
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

input::placeholder {
  color: #909399 !important;
}

.is-mobile .el-message, .el-message-error {
  width: 100vw !important;
  margin: 0 !important;
  padding: 10px 0 !important;
  displsy: flex;
  justify-content: center;
}

.footer{
  width: 100%;
  height: 50px;
  text-align: center;
  color: #666;
  line-height: 70px;
  font-weight: 500;
}
</style>
