<template>
    <div class="home">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px">
            <el-breadcrumb-item>资源管理</el-breadcrumb-item>
            <el-breadcrumb-item>矿机管理</el-breadcrumb-item>
            <el-breadcrumb-item>待提交设备审核</el-breadcrumb-item>
        </el-breadcrumb>
        <main class="download-wrap">
          <el-button type="primary"@click="dialogVisible = true" style="margin-left:auto;margin-bottom: 15px;margin-top: 15px;">批量提交</el-button>
          <el-dialog
            title="模版提交"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"> 
            <div>


              <el-row>
                <el-col :span="12"><span>模版下载</span></el-col>
                <el-col :span="12"><a
                  href="./../../动态IP汇聚设备批量提交模板.xlsx"
                  download="动态IP汇聚设备批量提交模板.xlsx"
                  class="each-item button"
                >下载</a></el-col>
              </el-row>

              <el-row>
                <el-col :span="12"><span>导入模版</span></el-col>
                <el-col :span="12"><el-upload
                  ref="upload"
                  action="/"
                  :show-file-list="false"
                  :on-change="importExcel"
                  :auto-upload="false"
                  class="each-item tempalte-upload-button"
                  :limit="1"
                  :multiple="false"
                >
                  <div
                    class="button"
                    type="primary"
                  >
                    选择
                  </div>
                </el-upload></el-col>
              </el-row>


                <!-- <div>
                  <div>
                    <span>模版下载</span>
                    <a
                      href="./../../动态IP汇聚设备批量提交模板.xlsx"
                      download="动态IP汇聚设备批量提交模板.xlsx"
                      class="each-item button"
                    >下载</a>
                  </div>
                </div> -->

                <!-- <div>
                  <div >
                    <span>导入模版</span>
                    <el-upload
                      ref="upload"
                      action="/"
                      :show-file-list="false"
                      :on-change="importExcel"
                      :auto-upload="false"
                      class="each-item tempalte-upload-button"
                      :limit="1"
                      :multiple="false"
                    >
                      <div
                        class="button"
                        type="primary"
                      >
                        选择
                      </div>
                    </el-upload>
                  </div>
                </div> -->

              </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="batchSubmit">确 定</el-button>
            </span>
          </el-dialog>
            <div class="crm-node-content">
                <div>
                    <el-table
                            :data="tableData"
                            align='center'
                    >
                        <el-table-column
                                prop="serverID"
                                align='center'
                                label="serverID"
                        >
                        </el-table-column>
                        <el-table-column
                                label="节点资源类型"
                                align='center'
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.serverNetType == 1">动态IP汇聚</div>
                                <div v-else>静态IP专线</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="serverNote"
                                label="设备备注"
                                align='center'
                        >
                        </el-table-column>
                        <el-table-column
                                prop="bandwidthMb"
                                align='center'
                                label="服务器总带宽Mbps"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="email"
                                align='center'
                                label="子账号"
                        >
                            <template slot-scope="scope">
                                <div>{{scope.row.serverAccount}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="city"
                                align='center'
                                label="归属地"
                        >
                            <template slot-scope="scope">
                                <div>{{ `${scope.row.province}-${scope.row.city}`}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="状态"
                                align='center'
                        >
                            <template slot-scope="scope">
                                <div>{{ getStatusText(scope.row) }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align='center'
                        >
                            <template slot-scope="scope">
                                <el-button size="mini" v-if="canEdit(scope.row)" @click="edit(scope.row)">编辑</el-button>
                                <el-button size="mini" type="danger" v-if="canReject(scope.row)" @click="reject(scope.row)">打回</el-button>
                                <el-button size="mini" type="primary" v-if="canCommit(scope.row)" @click="commit(scope.row)">提交</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <div style="text-align: right; margin-top: 10px">
                        <el-pagination
                                @current-change="handleCurrentChange"
                                :current-page.sync="currentPage"
                                :page-size="deviceSize"
                                layout="total, prev, pager, next"
                                :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </main>
        <!-- 节点提交 -->
        <el-dialog
                title="标准节点提交"
                width="60%"
                :visible.sync="showAddConfig"
                class=""
        >
            <div class="node-dialog-step node-step">
                <span class="template-title">第一步：请先选择你要提交的资源类型 </span>
                <div class="node-type-choose">
                    <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">资源类型</span>
                    <el-select
                            v-model="serverNetType"
                            placeholder="请选择"
                    >
                        <el-option
                                key=0
                                label="静态IP专线（1Gbps / 10Gbps）"
                                value=0
                        >
                        </el-option>
                        <el-option
                                key=1
                                label="动态IP汇聚（3Gbps - 5Gbps）"
                                value=1
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="bonus-tips margin-left-20">动态IP汇聚类型的资源需要填写拨号配置，请在软路由 - 汇聚拨号配置中填写。</div>
            </div>
            <div class="node-step node-dialog-step">
                <span class="template-title">第二步：填写服务器信息 </span>
                <div class="node-type-choose">
                    <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">设备备注</span>
                    <el-input
                            maxlength="80"
                            v-model="serverNote"
                    ></el-input>
                </div>
                <div class="node-type-choose">
                    <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title" >设备总带宽</span>
                    <el-input
                            maxlength="80"
                            v-model="bandwidthMb"
                            placeholder="只填写带宽数字，单位Mbps"
                            min="1"
                            type="number"
                    ></el-input>
                </div>
            </div>
            <div class="node-step node-dialog-step">
                <div
                        class="node-step margin-bottom-20"
                        v-if="serverNetType == '0'"
                >
                    <span class="template-title">第三步：填写设备登录方式</span>
                    <div class="node-step3-wrap">
                        <div class="item node-type-choose">
                            <!-- 登陆Ip -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">设备登陆IP</span>
                                <el-input
                                        maxlength="80"
                                        v-model="ip"
                                        placeholder="0.0.0.0"
                                ></el-input>
                            </div>
                            <!-- 登陆端口 -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登陆端口</span>
                                <el-input
                                        maxlength="80"
                                        v-model="serverJumperPort"
                                        placeholder="输入端口号，默认为22"
                                        type='number'
                                        min="1"
                                ></el-input>
                            </div>
                            <!-- 登录账号 -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登录账号</span>
                                <el-input
                                        maxlength="80"
                                        v-model="serverLoginAccount"
                                        placeholder="输入账号，默认为 root"
                                ></el-input>
                            </div>
                            <!-- 登录密码 -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登录密码</span>
                                <el-input
                                        minlength="12"
                                        maxlength="20"
                                        v-model="serverPasswd"
                                        placeholder="请设置12位以上的复杂密码，以防被黑"
                                ></el-input>
                            </div>
                            <!-- 备注 -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">备注</span>
                                <el-input
                                        maxlength="80"
                                        placeholder="选填"
                                        v-model="serverRecords"
                                ></el-input>
                            </div>

                        </div>
                        <div class="item node-tips">
                            <div class="recom margin-bottom-20">推荐配置：（1Gbps/10Gbps）</div>
                            <div class="tips-layout">
                                <div class="tips-wrap">
                                    <div class="tips">
                                        <div>CPU：8核/16核（推荐 E5 2650v2）</div>
                                        <div>内存：32GB/64GB</div>
                                        <div>数据盘：2TB /4TB /8TB SSD （推荐 Nvme）</div>
                                        <div>系统盘：100GB SSD （安装 CentOS 7.7 系统）</div>
                                        <div>获取系统镜像：<a href="https://bonuscloud-imgs.s3.cn-north-1.jdcloud-oss.com/BonusCloud-NormalNode-x86_64-v20200516.iso" class="bonus-color">点击下载</a></div>
                                        <div>镜像默认密码：bonuscloud2019</div>
                                        <div class="bonus-tips">注：请及时修改为复杂密码后提交，安装过程中无需进行额外操作，会有几次自动重启，时间略长，请耐心等待</div>
                                    </div>
                                </div>
                            </div>
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                        class="node-step margin-bottom-20"
                        v-if="serverNetType == '1'"
                >
                    <span class="template-title">第三步：填写设备登录方式</span>
                    <div class="node-step3-wrap">
                        <div class="item node-type-choose">
                            <!-- 是否需要跳转 -->
                            <div class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">是否需要跳转</span>
                                <el-select
                                        v-model="jumperIpControl"
                                        placeholder="请选择"
                                >
                                    <el-option
                                            key=1
                                            label="是"
                                            value=1
                                    >
                                    </el-option>
                                    <el-option
                                            key=2
                                            label="否"
                                            value=2
                                    >
                                    </el-option>
                                </el-select>
                                <div>
                                    <div class="margin-top-20 bonus-tips">(若设备通过同一外网IP/域名转内网，选择开启)</div>
                                </div>
                            </div>
                            <!-- 设备登录方式 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">设备登录方式</span>
                                <el-input
                                        v-if="jumperIpControl == 1"
                                        maxlength="80"
                                        v-model="ip"
                                        placeholder="N1 Mac/已上线主机名"
                                ></el-input>
                                <el-input
                                        v-if="jumperIpControl == 2"
                                        maxlength="80"
                                        v-model="ip"
                                        placeholder="IP/域名"
                                ></el-input>
                            </div>
                            <!-- 跳转内网IP -->
                            <div v-if="jumperIpControl == 1" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">跳转内网IP</span>
                                <el-input
                                        maxlength="80"
                                        v-model="serverIntIP"
                                        placeholder="IP"
                                ></el-input>
                            </div>
                            <!-- 远程端口 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">远程端口</span>
                                <el-input
                                        v-model="serverJumperPort"
                                        placeholder="只输入数字（优先映射22端口）"
                                        type='number'
                                        min="1"
                                ></el-input>
                            </div>
                            <!-- 网关地址 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">网关地址</span>
                                <el-input
                                        v-model="serverGateway"
                                        placeholder="IP"
                                ></el-input>
                            </div>
                            <!-- 登录密码 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">登录账号</span>
                                <el-input
                                        maxlength="80"
                                        v-model="serverLoginAccount"
                                        placeholder="输入账号，默认为 root"
                                ></el-input>
                            </div>
                            <!-- 登录密码 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">登录密码</span>
                                <el-input
                                        minlength="12"
                                        maxlength="20"
                                        placeholder="请设置12位以上的复杂密码，以防被黑"
                                        v-model="serverPasswd"
                                ></el-input>
                            </div>
                            <!-- 备注 -->
                            <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                                <span class="node-type-tittle margin-right-20 step3-title">备注</span>
                                <el-input
                                        maxlength="80"
                                        placeholder="是否需要做多拨/是否需要做Vlan等"
                                        v-model="serverRecords"
                                ></el-input>
                            </div>
                        </div>
                        <div class="item node-tips">
                            <div class="recom margin-bottom-20">汇聚必看：
                                <a
                                        class="bonus-tips"
                                        target="_blank"
                                        href="https://shimo.im/docs/QqJtpkCkYvRTQWvx"
                                >https://shimo.im/docs/QqJtpkCkYvRTQWvx</a>
                            </div>
                            <div class="recom margin-bottom-20">推荐配置：（最低配置）</div>
                            <div class="tips-layout">
                                <div class="tips-wrap">
                                    <div class="tips">
                                        <div>CPU：16核（推荐 E5 2650v2 *2）</div>
                                        <div>数据盘：4TB /8TB SSD （推荐 Nvme）</div>
                                        <div>系统盘：100GB SSD （安装 CentOS 7.7 系统）</div>
                                        <div>获取系统镜像：<a href="https://bonuscloud-imgs.s3.cn-north-1.jdcloud-oss.com/BonusCloud-NormalNode-x86_64-v20200516.iso" class="bonus-color">点击下载</a></div>
                                        <div>镜像默认密码：bonuscloud2019</div>
                                        <div class="bonus-tips">注：请及时修改为复杂密码后提交，安装过程中无需进行额外操作，会有几次自动重启，时间略长，请耐心等待</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="margin-20 node-button-wrap dial-config-footer">
                <div
                        class="red-button"
                        @click="showAddConfig = false"
                >取消</div>
                <div
                        class="button"
                        @click="commitNode"
                >保存</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import fetch from '../../utils/fetch';
  import { baseUrl } from '../../utils/env';
  import XLSX from "xlsx";
  const PAGE_SIZE = 20;

  // const STATUS = {
  //   // 0: "待提交",
  //   // 1: "已提交",
  //   2: "部署中",
  //   3: "部署完成",
  //   4: "无法登陆",
  //   5: "端口不通",
  //   6: "拨号错误",
  //   7: "硬件问题",
  //   8: "等待官方联系",
  //   9: "IP冲突" ,
  //   10: "待认证", //待认证
  //   11: "待确认", //待确认
  //   12: "部署错误", //待确认
  //   13: "拨号修改",
  //   14: "带宽修改",
  // };

  export default {
    name: 'AuditDevice',
    data() {
      return {
        tableData: [],
        total: 0,
        changStatus: [],
        deviceSize: PAGE_SIZE,
        currentPage: 1,
        // 编辑功能相关字段
        showAddConfig: false,
        commitType: "",
        dealServerId: '',
        serverID: '',
        serverNetType: "",
        serverNote: "", //设备备注
        bandwidthMb: "",
        ip: "",
        serverLoginAccount: "",
        serverPasswd: "",
        serverIntIP: "",
        serverJumperPort: "",
        serverGateway: "",
        serverRecords: "", //填写备注
        jumperIpControl: "",
        serverOwnerID: '',
        serverAccount: '',
        serverDeployStatus: '',
        auditPosition: '',
        dialogVisible:false, //批量提交对话框
        excelfile:'', //导入模版的信息
        batchSubmitList:[],//批量提交列表
      };
    },
    methods: {
      // 导入excel模版
      importExcel(file) {
        this.excelfile = file;
        const types = file.name.split(".")[1];
        const fileType = ["xlsx"].some(item => item === types);
        if (!fileType) {
          this.$message({
            type: "error",
            message: "格式错误！请重新选择"
          });
          return;
        }
      },
      //读取文件
      file2Xce(file) {
        return new Promise(function(resolve, reject) {
          const reader = new FileReader();
          reader.onload = function(e) {
            const data = e.target.result;
            this.wb = XLSX.read(data, {
              type: "binary"
            });
            const result = [];
            this.wb.SheetNames.forEach(sheetName => {
              result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
              });
            });
            console.log(result)
            resolve(result);
          };
          reader.readAsBinaryString(file.raw);
        });
      },
      //批量提交
      batchSubmit(){
        this.file2Xce(this.excelfile).then(async (tabJson) => {
          const missingerror = [];
          for(var i =0;i<tabJson[0].sheet.length;i++){
            if(
            !tabJson[0].sheet[i]['本地网关'] ||
            !tabJson[0].sheet[i]['设备备注'] ||
            !tabJson[0].sheet[i]['设备总带宽（只填写数字，单位Mbps）'] ||
            !tabJson[0].sheet[i]['设备登录方式（公网IP或动态域名）'] ||
            !tabJson[0].sheet[i]['远程端口（只输入数字）'] ||
            !tabJson[0].sheet[i]['登录账号（默认root）'] ||
            !tabJson[0].sheet[i]['登录密码（请修改为一个较为复杂的密码）']||
            !tabJson[0].sheet[i]['服务器归属账号（邮箱）'] ||
            !tabJson[0].sheet[i]['备注']){
             missingerror.push(1)
            }
          }
          //校验上传表格是否有空参数
          if(missingerror.length == 0){
            for(var i = 0;i<tabJson[0].sheet.length;i++){
              const batchSubmitItem = {
                serverAccount:(tabJson[0].sheet[i]['服务器归属账号（邮箱）']).toString(),
                serverNote:(tabJson[0].sheet[i]['设备备注']).toString(),
                bandwidthMb:(tabJson[0].sheet[i]['设备总带宽（只填写数字，单位Mbps）']).toString(),
                serverLoginAccount:(tabJson[0].sheet[i]['登录账号（默认root）']).toString(),
                serverPasswd:(tabJson[0].sheet[i]['登录密码（请修改为一个较为复杂的密码）']).toString(),
                serverGateway:(tabJson[0].sheet[i]['本地网关']).toString(),
                serverJumperIP:(tabJson[0].sheet[i]['设备登录方式（公网IP或动态域名）']).toString(),
                serverJumperPort:(tabJson[0].sheet[i]['远程端口（只输入数字）']).toString(),
                adminNote:(tabJson[0].sheet[i]['备注']).toString()
              }
              this.batchSubmitList.push(batchSubmitItem);
            }
            const batchSubmitList = this.batchSubmitList;
            //调批量提交接口
            const res = await fetch(`${baseUrl}/api/agent/batchSubmit`, {
              batchSubmitList
            }, 'POST');
            if (res.code === 200) {
              if(res.data == null){
                this.$message({
                  type:'success',
                  message:'模版批量提交成功'
                })
              } else{
                this.$message({
                  type:'warning',
                  message:'模版部分提交失败，失败信息（IP）：'+ res.data,
                  showClose:true,
                  duration:0,
                })
              }
              this.updateStandard({ page: this.currentPage });
            } else {
              this.$message({
                message: '模版提交失败，请稍后重试',
                type: 'error'
              })
            }
          }else{
              this.$message({
                type:'error',
                message:'导入模版缺少参数，请重新导入'
              })
            }
        })
        this.dialogVisible = false;
        this.batchSubmitList = [];
      },
  
      getStatusText (row) {
        const { serverDeployStatus, auditPosition } = row;
        switch (String(serverDeployStatus)) {
          // 0、13、14理论上不会出现在父账号审核的列表中
          case '0':
          case '13':
          case '14':
            return '';
          case '1':
            return {
              'ADMIN': '已提交，待部署'
            }[auditPosition];
          case '2':
          case '10':
          case '11':
          case '12':
            return '部署中';
          case '3':
            return '部署完成';
          case '4':
          case '5':
          case '6':
          case '7':
          case '8':
          case '9':
            return '拨号/配置有误，请检查信息重新提交'
        }
        return '';
      },
      canEdit(row) {
        // 只有在"待提交"状态下提交到父账号这里时，父账号可以编辑
        const { serverDeployStatus, auditPosition } = row;
        return serverDeployStatus == 1 && auditPosition === 'OWNER';
      },
      canCommit(row) {
        // 只有在"待提交"（这里的待提交指待提交至CRM管理员侧）状态下提交到父账号这里时，父账号可以审核提交
        const { serverDeployStatus, auditPosition } = row;
        return serverDeployStatus == 1 && auditPosition === 'OWNER';
      },
      canReject(row) {
        const { serverDeployStatus, auditPosition } = row;
        if (auditPosition !== 'OWNER') {
          return false;
        }
        return ['1', '4', '5', '6', '7', '8', '9'].includes(serverDeployStatus);
      },
      // 打回
      async reject(row) {
        const { serverDeployStatus, serverID } = row;
        const params = {
          serverID,
          serverDeployStatus,
          auditPosition: 'SELF_REJECTED'
        };
        // 已提交状态下打回需要变更状态为待提交
        if (serverDeployStatus == 1) {
          params.serverDeployStatus = '0';
        }
        const res = await fetch(`${baseUrl}/api/agent/audit`, params, 'POST');
        if (res.code === 200) {
          this.$message({
            message: '打回成功',
            type: 'success'
          });
          this.updateStandard({ page: this.currentPage });
        } else {
          this.$message({
            message: `打回失败：${res.message}`,
            type: 'error'
          });
        }
      },
      // 提交
      async commit(row) {
        const { serverDeployStatus, serverID } = row;
        const res = await fetch(`${baseUrl}/api/agent/audit`, {
          serverID,
          serverDeployStatus,
          auditPosition: 'ADMIN'
        }, 'POST');
        if (res.code === 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.updateStandard({ page: this.currentPage });
        } else {
          this.$message({
            message: `提交失败：${res.message}`,
            type: 'error'
          });
        }
      },
      edit(item) {
        this.commitType = "EDIT";
        this.showAddConfig = true;
        this.dealServerId = item.serverID;
        this.serverID = item.serverID;
        this.serverNetType = String(item.serverNetType);
        this.serverNote = item.serverNote;
        this.bandwidthMb = item.bandwidthMb;
        this.ip = item.serverJumperIP || item.serverExtIP;
        this.serverLoginAccount = item.serverLoginAccount;
        this.serverPasswd = item.serverPasswd;
        this.serverIntIP = item.serverIntIP;
        this.serverJumperPort = item.serverJumperPort;
        this.serverGateway = item.serverGateway;
        this.serverRecords = item.serverRecords;
        this.jumperIpControl = item.serverIntIP ? "1" : "2";
        this.serverOwnerID = item.serverOwnerID;
        this.serverAccount = item.serverAccount;
        this.serverDeployStatus = item.serverDeployStatus;
        this.auditPosition = item.auditPosition;
      },
      commitNode() {
        let {
          serverNetType,
          commitType,
          serverNote,
          bandwidthMb,
          serverRecords,
          ip,
          serverIntIP,
          serverPasswd,
          serverLoginAccount,
          serverJumperPort,
          serverGateway,
          dialConfigID,
          dealServerId,
          jumperIpControl,
          serverOwnerID,
          serverAccount,
          serverDeployStatus,
          auditPosition,
        } = this;

        let data = {
          serverNetType,
          serverNote,
          bandwidthMb,
          ip,
          serverPasswd,
          serverLoginAccount,
          serverRecords,
          commitType,
          dialConfigID,
          serverID: dealServerId,
          serverJumperPort,
          serverGateway,
          serverOwnerID,
          serverAccount,
          serverDeployStatus,
          auditPosition,
          isFromFather: true
        };

        if (serverNetType == 1) {
          if (jumperIpControl == 1) {
            data.serverIntIP = serverIntIP;
          }
        }
        // 动态网关地址判断
        if (serverNetType == 1 && !serverGateway) {
          return this.$message({
            message: "缺少网关地址",
            type: "error"
          });
        }

        if (!serverNote || !serverNetType || !ip || !serverPasswd || !serverLoginAccount || !bandwidthMb) {
          return this.$message({
            message: "缺少参数",
            type: "error"
          });
        }

        if (serverJumperPort && isNaN(serverJumperPort) || serverJumperPort < 0) {
          return this.$message({
            message: "请输入正确的端口",
            type: "error"
          });
        }

        // 防止多次操作
        const loading = this.$loading({
          lock: true,
          text: 'loading...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        fetch(`${baseUrl}/api/standardNode/setStandardNode`, data, 'POST').then(res => {
          loading.close();
          if (res.status === 200) {
            this.$message({
              message: "保存成功",
              type: "success"
            });
            this.updateStandard({ page: this.currentPage });
            this.showAddConfig = false;
          } else {
            this.$message({
              message: res.message || '网络问题，请重试',
              type: "error"
            });
          }
        });
      },
      async updateStandard({ page = 1 }) {
        const res = await fetch(`${baseUrl}/api/agent/getStandardNodeOfFather`, {
          auditPosition: 'OWNER,ADMIN',
          limit: this.deviceSize,
          type:1,
          page
        });
        if (res.code === 200) {
          const { list, total } = res.data;
          this.tableData = list;
          this.total = total;
          this.currentPage = page;
        } else {
          this.$message({
            message: `查询失败：${res.message}`,
            type: error
          });
        }
      },
      handleCurrentChange(val) {
        this.updateStandard({ page: val });
      },
    },
    created() {
      // 默认查询待父账号审核的机器
      this.updateStandard({ page: 1 });
    }
  };
</script>

<style  lang="stylus">
    .node-dial-content {
        width: 80%;
    }

    .crm-node-content{
        padding-top: 80px;
        height: auto;
        margin: 0 auto;
        background: #fff;
        padding: 20px;
        text-align: left;
    }
    .label-button{
        max-width: 100%;
    }
</style>
