<template>
  <div class="hard-node hard-wrap">
    <BasicLayout
      class="margin-top-20"
      title="标准节点提交"
    >
      <div
        class="button button dail-button"
        @click="checkAdd"
      >添加节点</div>
      <el-table
        v-if="nodeConfig !== 'NODATA'"
        :data="nodeConfig"
        align="left"
        empty-text="Loading..."
        style="width: 100%"
      >
        <!-- 节点资源类型 -->
        <el-table-column
          label="节点资源类型"
          align='center'
        >
          <template slot-scope="scope">
            <div v-if="scope.row.serverNetType == 0">静态IP专线（1Gbps / 10Gbps）</div>
            <div v-if="scope.row.serverNetType == 1">动态IP汇聚（3Gbps - 5Gbps）</div>
          </template>
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          prop="serverNote"
          label="备注"
          align='center'

        >
        </el-table-column>
        <!-- 节点带宽 -->
        <el-table-column
          prop="bandwidthMb"
          label="节点带宽"
          align='center'
        >
        </el-table-column>
        <!-- 拨号配置 -->
        <el-table-column
          label="拨号配置"
          align='center'
        >
          <template slot-scope="scope">
            <!-- 静态配置不需要进行拨号配置 -->
            <div v-if="scope.row.serverNetType == 0">-</div>
            <div v-if="scope.row.serverNetType == 1 && scope.row.serverConfigID && scope.row.serverConfigID != 0">{{dialNames[scope.row.serverConfigID]}}</div>
            <el-select
              class="dial-input"
              v-if="scope.row.serverNetType == 1 && (!scope.row.serverConfigID || scope.row.serverConfigID == 0) && newDialConfig.length"
              v-model="dialArray[scope.$index]"
              placeholder="请选择"
            >
              <el-option
                v-for="item in newDialConfig"
                v-if="item.dialStatus == 0"
                :label="item.dialConfigName"
                :value="item.dialConfigID"
                :key="item._id"
              >
              </el-option>
            </el-select>
            <div v-if="scope.row.serverNetType == 1 && (!scope.row.serverConfigID || scope.row.serverConfigID == 0 ) && (!newDialConfig.length)">
              <a href="/resource/dialConfig">前往拨号配置</a>
            </div>
          </template>
        </el-table-column>
        <!-- 状态-->
        <el-table-column
          label="状态"
          align='center'
        >
          <template slot-scope="scope">
            <div
              :class="['node-edit-tips', 'text-align-center', {'bonus-tip' : (scope.row.serverDeployStatus > 3  && scope.row.serverDeployStatus < 10)}]"
            >{{getStatusText(scope.row)}}</div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          align='center'
        >
          <template slot-scope="scope">
            <!-- -1 -->
            <!-- 1,2,3,9,10 不可操作 -->
            <!-- 0-未提交，可操作 -->
            <!-- 4-8-有问题，可操作 -->
            <div v-if="scope.row.serverDeployStatus == 0 || scope.row.serverDeployStatus == -1
              || (scope.row.serverDeployStatus > 3  && scope.row.serverDeployStatus < 10)">
              <div class="node-edit-wrap">
                <div
                  class="button"
                  v-if="scope.row.serverDeployStatus != -1 && ['SELF', 'SELF_REJECTED'].indexOf(scope.row.auditPosition) > -1"
                  @click="editConfig(scope.row)"
                >编辑</div>
                <!-- 已部署状态不可删除 -->
                <div
                  class="red-button"
                  v-if="scope.row.serverDeployStatus != -1 && ['SELF', 'SELF_REJECTED'].indexOf(scope.row.auditPosition) > -1"
                  @click="sureDeleteConfig(scope.row)"
                >删除</div>
                <div v-if="['SELF', 'SELF_REJECTED'].indexOf(scope.row.auditPosition) > -1"
                  class="button"
                  @click="sureCommitConfig(scope.row, scope.$index)"
                >提交</div>
              </div>
            </div>
            <!-- 部署完成的机器可以修改带宽 -->
            <div
              class="button margin-left-20"
              v-if="scope.row.serverDeployStatus == 3"
              @click="showChangeBandDialog = true;updateBandServerId = scope.row.serverID"
            >修改带宽</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="nodeConfig === 'NODATA'"
        align="left"
        empty-text="暂无数据"
        style="width: 100%"
      >
      </el-table>

      <!-- 节点提交 -->
      <el-dialog
        title="标准节点提交"
        width="60%"
        :visible.sync="showAddConfig"
        class=""
      >
        <div class="node-dialog-step node-step">
          <span class="template-title">第一步：请先选择你要提交的资源类型 </span>
          <div class="node-type-choose">
            <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">资源类型</span>
            <el-select
              v-model="serverNetType"
              placeholder="请选择"
            >
              <el-option
                key=0
                label="静态IP专线（1Gbps / 10Gbps）"
                value=0
              >
              </el-option>
              <el-option
                key=1
                label="动态IP汇聚（3Gbps - 5Gbps）"
                value=1
              >
              </el-option>
            </el-select>
          </div>
          <div class="bonus-tips margin-left-20">动态IP汇聚类型的资源需要填写拨号配置，请在拨号配置管理中填写。</div>
        </div>
        <div class="node-step node-dialog-step">
          <span class="template-title">第二步：填写服务器信息 </span>
          <div class="node-type-choose">
            <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">设备备注</span>
            <el-input
              maxlength="80"
              v-model="serverNote"
            ></el-input>
          </div>
          <div class="node-type-choose">
            <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title" >设备总带宽</span>
            <el-input
              maxlength="80"
              v-model="bandwidthMb"
              placeholder="只填写带宽数字，单位Mbps"
              min="1"
              type="number"
            ></el-input>
          </div>
        </div>
        <div class="node-step node-dialog-step">
          <div
            class="node-step margin-bottom-20"
            v-if="serverNetType == '0'"
          >
            <span class="template-title">第三步：填写设备登录方式</span>
            <div class="node-step3-wrap">
              <div class="item node-type-choose">
                <!-- 登陆Ip -->
                <div class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">设备登陆IP</span>
                  <el-input
                    maxlength="80"
                    v-model="ip"
                    placeholder="0.0.0.0"
                  ></el-input>
                </div>
                <!-- 登陆端口 -->
                <div class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登陆端口</span>
                  <el-input
                    maxlength="80"
                    v-model="serverJumperPort"
                    placeholder="输入端口号，默认为22"
                    type='number'
                    min="1"
                  ></el-input>
                </div>
                <!-- 登录账号 -->
                <div class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登录账号</span>
                  <el-input
                    maxlength="80"
                    v-model="serverLoginAccount"
                    placeholder="输入账号，默认为 root"
                  ></el-input>
                </div>
                <!-- 登录密码 -->
                <div class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">登录密码</span>
                  <el-input
                    minlength="12"
                    maxlength="20"
                    v-model="serverPasswd"
                    placeholder="请设置12位以上的复杂密码，以防被黑"
                  ></el-input>
                </div>
                <!-- 备注 -->
                <div class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-top-20 margin-bottom-20 step3-title">备注</span>
                  <el-input
                    maxlength="80"
                    placeholder="选填"
                    v-model="serverRecords"
                  ></el-input>
                </div>

              </div>
              <div class="item node-tips">
                <div class="recom margin-bottom-20">推荐配置：（1Gbps/10Gbps）</div>
                <div class="tips-layout">
                  <div class="tips-wrap">
                    <div class="tips">
                      <div>CPU：8核/16核（推荐 E5 2650v2）</div>
                      <div>内存：32GB/64GB</div>
                      <div>数据盘：2TB /4TB /8TB SSD （推荐 Nvme）</div>
                      <div>系统盘：100GB SSD （安装 CentOS 7.7 系统）</div>
                    </div>
                  </div>
                </div>
                <p>
                </p>
              </div>
            </div>
          </div>
          <div
            class="node-step margin-bottom-20"
            v-if="serverNetType == '1'"
          >
            <span class="template-title">第三步：填写设备登录方式</span>
            <div class="node-step3-wrap">
              <div class="item node-type-choose">
                <!-- 设备登录方式 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">设备登录方式</span>
                  <el-input
                    maxlength="80"
                    v-model="ip"
                    placeholder="IP/域名"
                  ></el-input>
                </div>
                <!-- 远程端口 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">远程端口</span>
                  <el-input
                    v-model="serverJumperPort"
                    placeholder="只输入数字（优先映射22端口）"
                    type='number'
                    min="1"
                  ></el-input>
                </div>
                <!-- 网关地址 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">网关地址</span>
                  <el-input
                    v-model="serverGateway"
                    placeholder="IP"
                  ></el-input>
                </div>
                <!-- 登录密码 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">登录账号</span>
                  <el-input
                    maxlength="80"
                    v-model="serverLoginAccount"
                    placeholder="输入账号，默认为 root"
                  ></el-input>
                </div>
                <!-- 登录密码 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">登录密码</span>
                  <el-input
                    minlength="12"
                    maxlength="20"
                    placeholder="请设置12位以上的复杂密码，以防被黑"
                    v-model="serverPasswd"
                  ></el-input>
                </div>
                <!-- 备注 -->
                <div v-if="jumperIpControl" class="node-step-input margin-bottom-20">
                  <span class="node-type-tittle margin-right-20 step3-title">备注</span>
                  <el-input
                    maxlength="80"
                    placeholder="是否需要做多拨/是否需要做Vlan等"
                    v-model="serverRecords"
                  ></el-input>
                </div>
              </div>
              <div class="item node-tips">
                <div class="recom margin-bottom-20">推荐配置：（最低配置）</div>
                <div class="tips-layout">
                  <div class="tips-wrap">
                    <div class="tips">
                      <div>CPU：16核（推荐 E5 2650v2 *2）</div>
                      <div>数据盘：4TB /8TB SSD （推荐 Nvme）</div>
                      <div>系统盘：100GB SSD （安装 CentOS 7.7 系统）</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="margin-20 node-button-wrap dial-config-footer">
          <div
            class="red-button"
            @click="showAddConfig = false"
          >取消</div>
          <div
            class="button"
            @click="commitNode"
          >保存</div>
        </div>
      </el-dialog>
      <!-- 删除Node -->
      <el-dialog
        title="提示"
        :visible.sync="showDeleteDialog"
      >
        <span>确定删除 {{dealNodeName}} 吗</span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showDeleteDialog = false">取 消</el-button>
          <el-button
            type="primary"
            @click="deleteNode"
          >确 定</el-button>
        </span>
      </el-dialog>
      <!-- 提交配置 -->
      <el-dialog
        title="提示"
        :visible.sync="showDialDialog"
      >
        <span>确定提交 {{dealNodeName}} 的拨号配置吗</span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showDialDialog = false">取 消</el-button>
          <el-button
            type="primary"
            @click="commitConfig"
          >确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改带宽 -->
      <el-dialog
        title="修改带宽"
        :visible.sync="showChangeBandDialog"
      >
        <div class="display-flex-center">
          <span class="margin-right-20">修改带宽: </span>
          <el-input
            maxlength="80"
            placeholder="只填写带宽数字，单位Mbps"
            min="1"
            type="number"
            v-model="updateBandWidth"
          ></el-input>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="showChangeBandDialog = false">取 消</el-button>
          <el-button
            type="primary"
            @click="changeBandwidths"
          >确 定</el-button>
        </span>
      </el-dialog>
    </BasicLayout>
  </div>
</template>

<i18n>
{
  "en": {
    "normoltTips": [
      "CPU：8核/16核（推荐 E5 2650v2）",
      "内存：32GB/64GB",
      "数据盘：2TB /4TB /8TB SSD （推荐 Nvme）",
      "系统盘：100GB SSD （安装 CentOS 7.6 系统）"
    ],
    "mergeTips": [
      "CPU：16核（推荐 E5 2650v2 *2）",
      "内存：64GB",
      "数据盘：4TB /8TB SSD （推荐 Nvme）",
      "系统盘：100GB SSD （安装 CentOS 7.6 系统）"
    ]
  }
}
</i18n>

<script>
import BasicLayout from "@/components/Common/BasicLayout.vue";
import fetch from '../../utils/fetch';
import { baseUrl } from '../../utils/env';
// const STATUS = {
//   "-1": "拨号配置缺失",
//   0: "待提交",
//   1: "已提交",
//   2: "部署中",
//   3: "部署完成",
//   4: "无法登陆",
//   5: "端口不通",
//   6: "拨号错误",
//   7: "硬件问题",
//   8: "等待官方联系",
//   9: "IP冲突", //可展示状态
//   10: "部署中", //待认证，不可展示状态
//   11: "部署中", //待确认，不可展示状态
//   12: "部署中",
//   13: "拨号修改中",
//   14: "带宽修改",
// };

export default {
  name: "Node",
  components: {
    BasicLayout
  },
  data() {
    return {
      showAddConfig: false,
      serverNetType: "",
      commitType: "",
      serverNote: "", //设备备注
      bandwidthMb: "",
      serverRecords: "", //填写备注
      ip: "",
      serverIntIP: "",
      serverPasswd: "",
      serverLoginAccount: "",
      serverJumperPort: "",
      serverGateway: "",
      dialArray: [],
      dealNodeName: "",
      dealNodeId: "",
      dealDialId: "",
      showDeleteDialog: false,
      showDialDialog: false,
      dialConfigID: "",
      dealCIID: "",
      jumperIpControl: "",
      showChangeBandDialog: false, //修改带宽
      updateBandWidth: '', //修改带宽
      updateBandServerId: '',
      nodeConfig: [],
      dialConfig: [],
      auditPosition: '',
    };
  },
  props: {},
  computed: {
    newDialConfig: function() {
      if (
        this.dialConfig &&
        this.dialConfig.length &&
        this.dialConfig !== 'NODATA'
      ) {
        return this.dialConfig.filter(item => item.dialStatus == 0);
      } else {
        return [];
      }
    },
    dialNames: function() {
      let dialName = {};
      if (this.dialConfig && this.dialConfig.length) {
        this.dialConfig.forEach(item => {
          dialName[item.dialConfigID] = item.dialConfigName;
        });
      }
      return dialName;
    }
  },
  methods: {
    // 展示状态的文案
    getStatusText(row) {
      const { serverDeployStatus, auditPosition } = row;
      switch (String(serverDeployStatus)) {
        case '0':
          return {
            'SELF': '待提交',
            'SELF_REJECTED': '被打回，待提交'
          }[auditPosition];
        case '1':
          return '已提交';
        case '2':
        case '10':
        case '11':
        case '12':
          return '部署中';
        case '3':
          return '部署完成';
        // 设备处于4-9的状态时，如果还流转在父账号下，暂时不需要让子账号感知到真实状态
        case '4':
          return {
            'SELF_REJECTED': '无法登陆',
            'OWNER': '部署中'
          }[auditPosition];
        case '5':
          return {
            'SELF_REJECTED': '端口不通',
            'OWNER': '部署中'
          }[auditPosition];
        case '6':
          return {
            'SELF_REJECTED': '拨号错误',
            'OWNER': '部署中'
          }[auditPosition];
        case '7':
          return {
            'SELF_REJECTED': '硬件问题',
            'OWNER': '部署中'
          }[auditPosition];
        case '8':
          return {
            'SELF_REJECTED': '等待官方联系',
            'OWNER': '部署中'
          }[auditPosition];
        case '9':
          return {
            'SELF_REJECTED': 'IP冲突',
            'OWNER': '部署中'
          }[auditPosition];
        case '13':
          return '拨号修改中';
        case '14':
          return '带宽修改';
      }
      return '';
    },
    checkAdd() {
      this.showAddConfig = true;
      this.commitType = "ADD";
      this.serverNote = '';
      this.serverRecords = '';
      this.ip = '',
              this.serverIntIP = '';
      this.serverPasswd = '';
      this.serverLoginAccount = '';
      this.serverJumperPort = '';
      this.dialConfigID = '';
      this.dealServerId = '';
      this.bandwidthMb = '';
      this.jumperIpControl = '2';
      this.serverGateway = '';
      this.auditPosition = 'SELF';
    },

    commitNode() {
      let {
        serverNetType,
        commitType,
        serverNote,
        bandwidthMb,
        serverRecords,
        ip,
        serverIntIP,
        serverPasswd,
        serverLoginAccount,
        serverJumperPort,
        serverGateway,
        dialConfigID,
        dealServerId,
        jumperIpControl,
        auditPosition
      } = this;

      let data = {
        serverNetType,
        serverNote,
        bandwidthMb,
        ip,
        serverPasswd,
        serverLoginAccount,
        serverRecords,
        commitType,
        dialConfigID,
        serverID: dealServerId,
        serverJumperPort,
        serverGateway,
        auditPosition
      };

      if (serverNetType == 1) {
        if (jumperIpControl == 1) {
          data.serverIntIP = serverIntIP;
        }
      }
      // 动态网关地址判断
      if (serverNetType == 1 && !serverGateway) {
        return this.$message({
          message: "缺少网关地址",
          type: "error"
        });
      }

      if (!serverNote || !serverNetType || !ip || !serverPasswd || !serverLoginAccount || !bandwidthMb) {
        return this.$message({
          message: "缺少参数",
          type: "error"
        });
      }

      if (serverJumperPort && isNaN(serverJumperPort) || serverJumperPort < 0) {
        return this.$message({
          message: "请输入正确的端口",
          type: "error"
        });
      }

      // 防止多次操作
      const loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      fetch(`${baseUrl}/api/standardNode/setStandardNode`, data, 'POST').then(res => {
        loading.close();
        if (res.status === 200) {
          this.$message({
            message: "保存成功",
            type: "success"
          });
          this.getStandardNodeList();
          this.showAddConfig = false;
        } else {
          this.$message({
            message: res.message || '网络问题，请重试',
            type: "error"
          });
        }
      });
    },
    editConfig(item) {
      this.commitType = "EDIT";
      this.showAddConfig = true;
      this.dealServerId = item.serverID;

      this.getStandardNode({ serverID: this.dealServerId }).then(res => {
        this.serverID = res.serverID;
        this.serverNetType = String(res.serverNetType);
        this.serverNote = res.serverNote;
        this.bandwidthMb = res.bandwidthMb;
        this.ip = res.serverJumperIP || res.serverExtIP;
        this.serverLoginAccount = res.serverLoginAccount;
        this.serverPasswd = res.serverPasswd;
        this.serverIntIP = res.serverIntIP;
        this.serverJumperPort = res.serverJumperPort;
        this.serverGateway = res.serverGateway;
        this.serverRecords = res.serverRecords;
        this.jumperIpControl = res.serverIntIP ? "1" : "2";
        this.auditPosition = res.auditPosition;
      });
    },
    sureDeleteConfig(item) {
      this.dealNodeId = item.serverID;
      this.dealCIID = item._id;
      this.dealNodeName = item.serverNote;
      this.showDeleteDialog = true;
    },
    deleteNode() {
      let dealCIID = this.dealCIID;
      console.log({serverCIID: dealCIID})
      // 防止多次操作
      const loading = this.$loading({
        lock: true,
        text: 'loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      fetch(`${baseUrl}/api/standardNode/deleteNode`, {
        serverCIID: dealCIID
      }, 'POST').then(res => {
        loading.close();
        if (res.status === 200) {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.showDeleteDialog = false;
          this.getStandardNodeList();
        } else {
          this.$message({
            message: res.message || '网络问题，请重试',
            type: "error"
          });
        }
      });
    },
    sureCommitConfig(item, index) {
      this.dealNodeName = item.serverNote;
      this.dealDialId = this.dialArray[index];
      this.dealNodeId = item.serverID;

      if (item.serverNetType == 1 && !this.dialArray[index]) {
        return this.$message({
          message: "请先选择拨号配置",
          type: "error"
        });
      } else {
        this.showDialDialog = true;
      }
    },
    commitConfig() {
      let { dealDialId, dealNodeId } = this;

      fetch(`${baseUrl}/api/standardNode/setNodesDialConfig`, {
        dialConfigID: dealDialId,
        serverID: dealNodeId,
        isFromAgent: true
      }, 'POST').then(res => {
        if (res.status === 200) {
          this.$message({
            message: "设置成功",
            type: "success"
          });
          this.getStandardNodeList();
          this.showDialDialog = false;
        } else {
          this.$message({
            message: res.message || '网络问题，请重试',
            type: "error"
          });
        }
      });
    },
    // 修改带宽
    changeBandwidths(item) {
      let { updateBandWidth, updateBandServerId } = this;
      fetch(`${baseUrl}/api/standardNode/updateBand`, {
        updateBandWidth,
        updateBandServerId
      }, 'POST').then(res => {
        if (res.code === 200) {
          this.$message({
            message: "更新成功",
            type: "success"
          });
          this.showChangeBandDialog = false;
        } else {
          this.$message({
            message: res.message,
            type: "error"
          });
        }
      });
    },
    async getStandardNode(data) {
      const res = await fetch(baseUrl + '/api/standardNode/getStandardNode', data);
      if (data && data.serverID) {
        return res.data[0];
      } else {
        if (res.data && res.data.length) {
          return res.data;
        }
      }
    },
    async getStandardNodeList() {
      const res = await fetch(`${baseUrl}/api/agent/getStandardNodeList`);
      if (res.code === 200 && Array.isArray(res.data) && res.data.length) {
        this.nodeConfig = res.data;
      } else {
        this.nodeConfig = 'NODATA';
      }
    },
    async getDialConfig(data) {
      const res = await fetch(`${baseUrl}/api/standardNode/getDialConfig`, data);
      if (res.data && res.data.length) {
        this.dialConfig = res.data;
      } else if (data && data.configed) {
        this.dialConfig = [];
      } else {
        this.dialConfig = "NODATA";
      }
    }
  },
  created() {
    this.getStandardNodeList();
    this.getDialConfig({ configed: true });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus">
.node-type-choose {
  margin: 20px;
}

.hard-node .dial-input.el-select, .hard-node .dial-input .el-input {
  width: 120px;
}

.hard-node .el-input {
  width: 230px;
  display: inline-block;
}

.hard-node .el-select {
  max-width: 100px !important;
}

.node-step3-wrap {
  display: flex;

  .item {
    width: 50%;
  }

  .node-tips {
    margin: 20px;
  }
}

.node-type-tittle {
  display: inline-block;
  max-width: 200px;
  min-width: 100px;
}

.node-edit-wrap {
  display: flex;
}

.node-dialog-step {
  margin: 20px 30px 60px;
  text-align: left;
}

.node-button-wrap {
  display: flex;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.hard-wrap .el-dialog__header {
  text-align: center;
  font-size: 28px;
}

.hard-wrap .el-dialog__title {
  font-size: 26px;
}
</style>
